/**
 * /* eslint-disable no-restricted-syntax
 *
 * @format
 */

/** @format */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
// localStorage.setItem('user_name', 'Rohit'); //store a key/value
// var retrievedUsername = localStorage.getItem('user_name'); //retrieve the key

import { makeAutoObservable, runInAction, toJS } from "mobx";
import moment from "moment";
import socketIoClient from "socket.io-client";
import { NotificationManager } from "../../components/common/react-notifications";
import {
    AUTOMATIC,
    AUTO_COORDINATES_RESPONSE,
    BATCH_PROGRESS_COUNT,
    CLASS_LIST,
    CURRENT_BATCH_STATUS,
    CURRENT_PROCESS_PERCENTAGE,
    MANUAL,
    ON_BATCH_COMPLETE,
    PROCESSED_DATA_RESPONSE,
    START,
} from "../../constants";
import { REFACTORDATA } from "../../constants/refactorData";
import LogService from "../../services/LogService";
import ManageDataService from "../../services/ManageDataService";
// eslint-disable-next-line import/no-cycle
import UserService from "../../services/UserService";
import { baseURL, socketBaseURL } from "../../utils/API";

export default class UIState {
    currentSelectedFile = undefined;

    annotatePages = {};

    currentSelectedPage = "";

    currentSelectedPageNumber = 0;

    socketId = undefined;

    imgPreviewPath = undefined;
    // imgPreviewPath = `${baseURL}/uploads/manual/j2ab15hbke6`;

    currentFolderPath: any = undefined;

    pdfDocPath = undefined;

    currentFilePath: any;

    totalPages: any;

    socket: any;

    processedData = undefined;

    isUploading = false;

    isProcessing = false;

    processType = undefined;

    labelKeyMap = {};

    metaDataId = undefined;

    isProcessExecuted = false;

    templateConfigurations = {};

    selectedTemplateId = undefined;

    openConfirmFooterModal = false;

    currentTemplateType = "";

    batchNotifications = [];

    isDataSaved = false;

    // CHECK IN LOCALSTORAGE IF IT IS MORE THAN ZERO THEN SET IT TO MOBX STATE
    batchProgressCountLocalStorage = Number(localStorage.getItem(BATCH_PROGRESS_COUNT));

    batchProgressCount = this.batchProgressCountLocalStorage > 0 ? this.batchProgressCountLocalStorage : 0;

    isProcessedDataEmmited = false;

    cvProcessingProgress = 0;

    currentUserId = undefined;

    currentUserType = undefined;

    metaData = undefined;

    cvMetaData: [] = [];

    cvCandidateData: [] = [];

    cvCandidateSearchedData: [] = [];

    currentProcessedDataId = "";

    totalDataCount = 0;

    processedDataCount = 0;

    cvStatusData = [];

    searchCount = 0;

    constructor() {
        // mapping key value to replace code names of key
        CLASS_LIST.forEach((obj) => {
            this.labelKeyMap[obj.value] = obj.className;
        });
        makeAutoObservable(this);
    }

    // computed properties
    // checking yolo coordinates arrived
    get isAutoProcessDone() {
        if (this.processType === AUTOMATIC) {
            return this.annotatePages[this.currentSelectedPage] && this.annotatePages[this.currentSelectedPage].length !== 0;
        }
        if (this.processType === MANUAL) {
            return true;
        }
        return true;
    }

    // TO INITIALIZE SOCKET AND SAVE SOCKET ID
    socketInit() {
        const socket = socketIoClient(socketBaseURL);

        this.socket = socket;
        socket.on("connect", () => {
            this.socketId = socket.id;

            this.socket.emit("createProgressRoom", { userId: this.currentUserId });
            this.onSocketListen();

            // this.fetchMetaData(this.currentUserType);
            this.updateTotalDataCount(this.currentUserType);
        });
    }

    // common function to update states
    updateState(stateName, stateData): void {
        this[stateName] = stateData;
    }

    // set batchProgresscount
    setBatchProgressCount = (count) => {
        this.batchProgressCount = count;
    };

    // SOCKET EVENT LISTENERS
    onSocketListen() {
        // on extracted process data response
        this.socket.on(PROCESSED_DATA_RESPONSE, async (response) => {
            LogService.info("processed data response 🔵 ", response);

            if (response) {
                this.isProcessedDataEmmited = true;
                this.cvProcessingProgress = 100;
                this.isProcessing = false;
                setTimeout(() => {
                    this.refactorProcessedData({ ...response });
                }, 1300);
            }

            if (!response) {
                NotificationManager.error("data-extration-error", "Data Extraction Error", 3000);
                this.isProcessing = false;
            }
        });

        // on yolo cooridnates response
        this.socket.on(AUTO_COORDINATES_RESPONSE, async (response) => {
            LogService.info("auto coordinates  response 🔵 ", response);
            if (Array.isArray(response)) {
                this.annotatePages[this.currentSelectedPage] = [...response];
            }

            if (!Array.isArray(response)) {
                this.annotatePages = { ...response };
            }
        });
        // on batch complete resposne

        this.socket.on(ON_BATCH_COMPLETE, async (response) => {
            LogService.info("batch completed", response);
            this.batchNotifications.push({
                title: "Batch process completed",
                date: moment().format("dddd-mm-yyyy hh:mm:ss"),
                id: Math.random().toString(16).slice(2),
            });

            this.setBatchProgressCount(0);
            localStorage.setItem("batchStartTime", undefined);
            //
            localStorage.setItem(BATCH_PROGRESS_COUNT, String(0));
            // after completing batch fetch new updated data in chunks
            this.fetchMetaData(this.currentUserType);
        });

        // on listen batch status
        this.socket.on(`${CURRENT_PROCESS_PERCENTAGE}`, async (response) => {
            //
            this.setBatchProgressCount(response);
            localStorage.setItem(BATCH_PROGRESS_COUNT, String(response));
        });

        // on connect listen batch status
        this.socket.on(CURRENT_BATCH_STATUS, async (response) => {
            if (response.batchStatus === false) {
                this.setBatchProgressCount(0);
                localStorage.setItem(BATCH_PROGRESS_COUNT, String(0));
            }
        });
    }

    increamentCVProcessingProgress(command) {
        const refeshIntervalId = setInterval(() => {
            if (command === START) {
                if (this.cvProcessingProgress <= 94) this.cvProcessingProgress += 2;
                if (this.cvProcessingProgress === 100) clearInterval(refeshIntervalId);
            }
        }, 200);
    }

    // refactoring processed data coming from server as per react table format
    refactorProcessedData(data) {
        const refactoredData = [];
        // eslint-disable-next-line no-param-reassign
        // data = REFACTORDATA(data);
        //
        // FIXME: IMPORVE PERFORMANCE
        // CLASS_LIST.forEach((list) => {
        //     Object.keys(data).forEach((key) => {
        //         if (key === list.value) refactoredData.push({ label: key, data: data[key] });
        //     });
        // });
        Object.keys(data).forEach((key) => {
            refactoredData.push({ label: key, data: data[key] });
        });

        // REFACTORDATA ==> will add empty input box for unmarked/unextrated classname
        this.processedData = [...refactoredData];

        this.processedData = REFACTORDATA(this.processedData);

        // setting is processing stat to false
        this.setLoadingState("isProcessing", false);
    }

    // update react table processed data
    updateProcessedData({ rowIndex, columnId, value, isChecked }) {
        this.processedData.forEach((row: any, index) => {
            if (index === rowIndex) {
                // updating the index of process data obj as with map not working
                this.processedData[index] = {
                    ...this.processedData[rowIndex],
                    [columnId]: value,
                    isChecked,
                };
            }
            return row;
        });
    }

    setSelectedFile(file) {
        this.currentSelectedFile = file;
    }

    /**
     * setCurrentServerPath
     * saving current file path in state
     */
    public setCurrentImgPath(data) {
        if (data !== "") {
            const { isPdf, path, fileData, processType, pdfPath } = data;

            this.currentSelectedPage = `page-1`;
            this.processType = processType;

            // IF FILE IS NOT A PDF AND SINGLE IMAGE
            if (!isPdf) {
                this.imgPreviewPath = `${baseURL}/${path}`;
                this.totalPages = 1;
                this.currentFilePath = path;
            }

            // IF FILE IS  A PDF AND SINGLE PAGE
            if (isPdf && fileData.pages === 1) {
                this.imgPreviewPath = `${baseURL}/${fileData.filePath}`;
                this.currentFolderPath = undefined;
                this.totalPages = +fileData.pages;
                this.currentFilePath = fileData.filePath;
                this.pdfDocPath = `${baseURL}/${pdfPath}`;
            }
            // IF FILE IS  A PDF AND MULTI PAGE
            if (isPdf && fileData.pages > 1) {
                this.imgPreviewPath = `${baseURL}/${fileData.folderPath}/page-1.png`;
                this.currentFolderPath = fileData.folderPath;
                this.totalPages = +fileData.pages;
            }
        }
        // setting initial state if move back in the page
        else {
            this.currentSelectedPage = "";
            this.processType = undefined;
            this.imgPreviewPath = undefined;
            this.totalPages = undefined;
            this.currentFilePath = undefined;
            this.currentFolderPath = undefined;
            this.totalPages = undefined;
        }
        // SETTING IS UPLOADING STATE TO FALSE
        this.isUploading = false;
    }

    /**
     * setCurrentPage
     * updating the current page state
     */
    public setCurrentPage(pageNumber) {
        this.currentSelectedPage = `page-${pageNumber}`;
        this.currentSelectedPageNumber = +pageNumber;
        this.imgPreviewPath = `${baseURL}/${this.currentFolderPath}/${this.currentSelectedPage}.png`;
    }

    getCurrentPageAnnotateData() {
        return this.annotatePages[this.currentSelectedPage];
    }

    setAnnotatePageData(data) {
        this.annotatePages[this.currentSelectedPage] = data;
    }

    setLoadingState(type: "isProcessing" | "isUploading", status) {
        if (type === "isUploading") {
            this.isUploading = status;
        }

        if (type === "isProcessing") {
            this.isProcessing = status;
        }
    }

    resetPreviewImage() {
        const imgPath = this.imgPreviewPath;
        this.imgPreviewPath = "";

        setTimeout(() => {
            runInAction(() => {
                this.imgPreviewPath = imgPath;
            });
        }, 100);
    }

    // * Actions to change totalPages , currentFolderPath , currentFilePath,
    // * and processedData , metaDataId
    setTotalPages(pages) {
        this.totalPages = pages;
    }

    setCurrentFolderPath(folderPath) {
        this.currentFolderPath = folderPath;
    }

    setCurrentFilePath(filePath) {
        this.currentFilePath = filePath;
    }

    setProcessedData(data) {
        this.processedData = data;
    }

    setMetaDataId(id) {
        this.metaDataId = id;
    }

    setMetaData(metaData) {
        this.metaData = metaData;
    }

    // reset uploaded and processed states
    resetData() {
        LogService.info("Resetting all on start over");

        this.currentSelectedFile = undefined;

        this.annotatePages = {};

        this.currentSelectedPage = "";

        this.currentSelectedPageNumber = 0;

        this.imgPreviewPath = undefined;

        this.currentFolderPath = undefined;

        this.currentFilePath = undefined;

        this.totalPages = undefined;

        this.openConfirmFooterModal = false;

        this.processedData = undefined;

        this.metaData = undefined;

        this.metaDataId = undefined;

        this.isDataSaved = false;

        this.isProcessedDataEmmited = false;

        this.cvProcessingProgress = 0;

        // this.increamentCVProcessingProgress(STOP);
    }

    // remove image and data
    async clearImageAndStates() {
        LogService.info("removing states and data when umount compo");
        const isFolder = !!this.currentFolderPath;
        const filePath = isFolder ? this.currentFolderPath : this.currentFilePath;
        // sending service call when their is any value in both
        if (isFolder !== undefined && filePath !== undefined) {
            runInAction(async () => {
                await UserService.removeImage({ isFolder, filePath });
                this.resetData();
            });
        }
    }

    // updating the current session metadata
    updateCurrentMetaDataId(metaDataId) {
        this.metaDataId = metaDataId;
    }

    // * states for searching

    name: string = "";

    email: string = "";

    location: string = "";

    work_experience: string = "";

    education: string = "";

    searchResult = [];

    message = "";

    gender = "";

    age = undefined;

    isSearchModalOpen = true;

    isLoading = false;

    startTime: Date = undefined;

    endTime: Date = undefined;

    timeInterval = undefined;

    isSearched = false;

    toggleSearchModalOpen = () => {
        this.isSearchModalOpen = !this.isSearchModalOpen;
        this.message = "";
    };

    setName = (name) => {
        this.name = name;
    };

    setEmail = (email) => {
        this.email = email;
    };

    setLocation = (location) => {
        this.location = location;
    };

    setWork_Experience = (work_experience) => {
        this.work_experience = work_experience;
    };

    setEducation = (education) => {
        this.education = education;
    };

    setGender = (gender) => {
        this.gender = gender;
    };

    setAge = (age) => {
        this.age = age;
    };

    setSearchResult = (searchResult) => {
        this.searchResult = searchResult;
    };

    setMessage = (message) => {
        this.message = message;
    };

    setIsLoading = (loading) => {
        this.isLoading = loading;
    };

    setStartTime = () => {
        this.startTime = new Date();
    };

    setEndTime = () => {
        this.endTime = new Date();
    };

    setTimeInterval = () => {
        this.timeInterval = Math.abs((this.endTime.getTime() - this.startTime.getTime()) / 1000);
    };

    setIsSearched = (value) => {
        this.isSearched = value;
    };

    trimSearch() {
        if (this.name) this.name = this.name.trim();
        if (this.email) this.email = this.email.trim();
        if (this.location) this.location = this.location.trim();
        if (this.work_experience) this.work_experience = this.work_experience.trim();
        if (this.education) this.education = this.education.trim();
    }

    search = async () => {
        // TRIME BEFORE SENDING FOR SEARCHING IN DB
        this.trimSearch();

        this.cvCandidateSearchedData = [];
        this.setIsLoading(true);
        this.setStartTime();
        this.setIsSearched(true);
        const responce = await ManageDataService.search(
            this.name,
            this.email,
            this.location,
            this.work_experience,
            this.education,
            this.gender,
            this.age,
            0,
            10
        );
        //

        runInAction(() => {
            if (responce) {
                const { metaData, success, count } = responce;
                this.setEndTime();
                this.setTimeInterval();
                this.setIsLoading(false);
                this.searchCount = count;

                if (success) {
                    this.setMessage(success);
                }

                if (metaData) {
                    // this.setSearchResult(metaData);
                    this.cvCandidateSearchedData = this.refactorMetadata(metaData);
                }
            }
        });
    };

    resetSearchState = () => {
        this.name = undefined;

        this.email = undefined;

        this.location = undefined;

        this.work_experience = undefined;

        this.education = undefined;

        this.gender = "";

        this.age = undefined;

        // this.searchResult = [];

        this.cvCandidateSearchedData = [];

        this.isSearchModalOpen = true;

        this.message = "";

        this.startTime = undefined;

        this.endTime = undefined;

        this.isSearched = false;
    };

    // audit screen states

    currentSelectedAuditUserType = "";

    currentSelectedAuditModuleName = "";

    // website logo main
    websiteMainLogo = "";

    // website logo main
    footerLogo = "";

    // website logo main
    faviconLogo = "";

    // website footer text
    footerText = "";

    // fetch metadata
    fetchMetaData = async (userType) => {
        let dataCount;
        console.log(userType);

        // IF USERTYPE IS NOT SUPERADMIN THEN ONLY FETCH METADATA AND COUNT
        if (userType !== "superadmin") dataCount = await UserService.getMetaDataCount(userType, this.currentUserId);
        if (dataCount) dataCount = dataCount.count;
        if (!dataCount) dataCount = 0;

        const startCount = 0;

        // imp offset range is to data pull data in how much chunks
        const OFFSET_RANGE = 500;
        const limit = OFFSET_RANGE;

        let finalFetchedData;
        finalFetchedData = [];
        this.setIsLoading(true);
        async function fetchDataInChunks(iterateCount: any, dataOffsetCount: any, dataLimit, that): Promise<any> {
            let count = iterateCount;
            let offset = dataOffsetCount;

            if (count > 0) {
                LogService.info("offset,limit ", { limit, offset });
                // TODO: SPLIT IN TO OHTER FUNCTION,

                // TODO: CREATE STRING CONSTANTS
                const fetchedMetaData = await UserService.getMetaData_v2(userType, that.currentUserId, dataOffsetCount, dataLimit);
                // fetch data in chunks

                if (fetchedMetaData) {
                    that.setIsLoading(false);
                }
                // eslint-disable-next-line no-param-reassign
                finalFetchedData = [...finalFetchedData, ...fetchedMetaData.metaData];
                // updating main state metadata with merged fetched data
                runInAction(() => {
                    // eslint-disable-next-line no-param-reassign
                    that.cvMetaData = finalFetchedData;
                });

                setTimeout(() => {
                    // set recursive count states
                    count -= OFFSET_RANGE;
                    offset += OFFSET_RANGE;

                    if (iterateCount === finalFetchedData.length) {
                        count = 0;
                    }
                    fetchDataInChunks(count, offset, dataLimit, that);
                }, 1000);
                return finalFetchedData;
            }

            return finalFetchedData;
        }

        await fetchDataInChunks(dataCount, startCount, limit, this);
    };

    // update cvMeta data  state
    cvMetaDataOperations = async (type, cvId, data?, isSearch?) => {
        //
        //
        //
        if (type === "update") {
            const updateCvMetaData: [] = this.cvMetaData.map((cvObj: any) => {
                // eslint-disable-next-line no-underscore-dangle
                if (cvObj._id === cvId) {
                    // eslint-disable-next-line no-param-reassign
                    cvObj = { ...cvObj, ...data };

                    return cvObj;
                }
                return cvObj;
            }) as [];
            this.cvMetaData = [...updateCvMetaData];

            // if it is a search screen
            // TODO: MOVE IN OTHER FUNCTION
            // TODO: UPDATE SEARCH DATA ON INTERNAL STATE LIKE CANDIDATE
            // if (isSearch) {
            //     console.log("this.cvCandidateSearchedData: ", toJS(this.cvCandidateSearchedData));
            //     console.log("cvId: ", cvId);
            //     const updateCvSearchData: [] = this.cvCandidateSearchedData.map((cvObj: any) => {
            //         console.log("cvObj.metaDataId === cvId: ", cvObj.metaDataId === cvId);
            //         // eslint-disable-next-line no-underscore-dangle
            //         if (cvObj.metaDataId === cvId) {
            //             console.log("data: ", toJS(data));
            //             // eslint-disable-next-line no-param-reassign
            //             cvObj = { ...cvObj, ...data };
            //             console.log("cvObj: ", toJS(cvObj));

            //             return cvObj;
            //         }
            //         return cvObj;
            //     }) as [];
            //     console.log("updateCvSearchData: ", toJS(updateCvSearchData));
            //     this.cvCandidateSearchedData = [...updateCvSearchData];
            // }
        }

        if (type === "delete") {
            const updateCvMetaData: [] = this.cvMetaData.filter((cvObj: any) => {
                // eslint-disable-next-line no-underscore-dangle
                return cvObj._id !== cvId;
            }) as [];
            this.cvMetaData = [...updateCvMetaData];
        }
    };

    // refactor data before fiding to the material table
    refactorMetadata = (metaData: any, filter: any = [], sort = {}) => {
        // eslint-disable-next-line no-param-reassign
        if (this.isSearched === false) metaData = metaData.filter((d: any) => d.status === "processed");

        // * refactoring metadata for material table
        let newprossedDataArray = metaData.map((metadata) => {
            const returnObject = {};
            const metadatakeys = Object.keys(metadata);
            // eslint-disable-next-line array-callback-return
            metadatakeys.map((metadatakey) => {
                if (metadatakey === "_id") {
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    returnObject[`metaDataId`] = metadata._id;
                }
                if (metadatakey === "processedDataId") {
                    const { processedDataId } = metadata;
                    const processedDataKeys = Object.keys(processedDataId);
                    // eslint-disable-next-line array-callback-return
                    processedDataKeys.map((processeddatakey) => {
                        if (processeddatakey === "processedData") {
                            const { processedData } = processedDataId;
                            // eslint-disable-next-line array-callback-return
                            processedData.map((processeddata) => {
                                returnObject[`${processeddata.label}`] = processeddata.data;
                            });
                        } else {
                            returnObject[`${processeddatakey}`] = processedDataId[`${processeddatakey}`];
                        }
                    });
                } else if (
                    metadatakey === "fileId" ||
                    metadatakey === "isAutomatic" ||
                    metadatakey === "isSemiAutomatic" ||
                    metadatakey === "updatedAt" ||
                    metadatakey === "__v" ||
                    metadatakey === "isActive"
                ) {
                    // pass
                } else {
                    returnObject[`${metadatakey}`] = metadata[metadatakey];
                }
            });
            console.log(returnObject);
            return returnObject;
        });

        // eslint-disable-next-line
        const nameIndex = filter.findIndex((item) => item.hasOwnProperty("name"));
        if (nameIndex !== -1) {
            // eslint-disable-next-line
            const value = new RegExp(filter[nameIndex]["name"].$regex , "i");
            newprossedDataArray = newprossedDataArray.filter((x) => value.test(x.name));
            // console.log(data);
        }
        // eslint-disable-next-line
        const emailIndex = filter.findIndex((item) => item.hasOwnProperty("email_id"));
        if (emailIndex !== -1) {
            // eslint-disable-next-line
            const value = new RegExp(filter[emailIndex]["email_id"].$regex , "i");
            newprossedDataArray = newprossedDataArray.filter((x) => value.test(x.email_id));
        }
        // eslint-disable-next-line
        const nationalityIndex = filter.findIndex((item) => item.hasOwnProperty("nationality"));
        if (nationalityIndex !== -1) {
            // eslint-disable-next-line
            const value = new RegExp(filter[nationalityIndex]["nationality"].$regex , "i");
            newprossedDataArray = newprossedDataArray.filter((x) => value.test(x.nationality));
        }
        // eslint-disable-next-line
        const genderIndex = filter.findIndex((item) => item.hasOwnProperty("gender"));
        if (genderIndex !== -1) {
            // eslint-disable-next-line
            const value = new RegExp(filter[genderIndex]["gender"].$regex , "i");
            newprossedDataArray = newprossedDataArray.filter((x) => value.test(x.gender));
        }
        // eslint-disable-next-line
        const phoneIndex = filter.findIndex((item) => item.hasOwnProperty("phone_number"));
        if (phoneIndex !== -1) {
            // eslint-disable-next-line
            const value = new RegExp(filter[phoneIndex]["phone_number"].$regex , "i");
            newprossedDataArray = newprossedDataArray.filter((x) => value.test(x.phone_number));
        }

        // eslint-disable-next-line
        if (sort['name'] !== undefined && sort['name'] !== 0 && sort['dir'] !== 0) {
            let fieldName = "";
            // eslint-disable-next-line
            if (sort['name'] === 'name') {
                fieldName = "candidateName";
            }

            // eslint-disable-next-line
            if (sort['name'] === 'email_id') {
                fieldName = "candidateEmail";
            }

            // eslint-disable-next-line
            if (sort['name'] === 'createdAt') {
                fieldName = "createdAt";
            }

            // eslint-disable-next-line
            if (sort['name'] === 'updatedAt') {
                fieldName = "updatedAt";
            }

            // eslint-disable-next-line
            if (sort['name'] === 'nationality') {
                fieldName = "nationality";
            }

            // eslint-disable-next-line
            if (sort['name'] === 'gender') {
                fieldName = "gender";
            }

            // eslint-disable-next-line
            if (sort['name'] === 'phone_number') {
                fieldName = "phone_number";
            }

            // eslint-disable-next-line
            if (sort['name'] === 'status') {
                fieldName = "status";
            }

            // eslint-disable-next-line
            // newprossedDataArray.sort((a, b) => {
            //     // eslint-disable-next-line
            //     if ((a[fieldName] !== undefined && a[fieldName] !== null) && (b[fieldName] !== undefined && b[fieldName] !== null)) {
            //         const nameA = a[fieldName].toLowerCase();
            //         const nameB = b[fieldName].toLowerCase();
            //         let dirCondition: any = "";
            //         // eslint-disable-next-line
            //         if (sort["dir"] === 'desc') {
            //             dirCondition = nameA > nameB;
            //             // eslint-disable-next-line
            //         } else if (sort["dir"] === 'asc') {
            //             dirCondition = nameA < nameB;
            //         }
            //         if (dirCondition) {
            //             return -1; // Return -1 to place 'a' before 'b'
            //             // eslint-disable-next-line
            //         } else if (nameA < nameB) {
            //             return 1; // Return 1 to place 'a' after 'b'
            //         }

            //     }
            //     return 0; // Return 0 to leave the ordering unchanged
            // });
            // eslint-disable-next-line
            if (sort["dir"] === 'asc') {
                newprossedDataArray.sort((a, b) => a?.[fieldName].localeCompare(b?.[fieldName]));
            }
            // eslint-disable-next-line
            if (sort["dir"] === 'desc') {
                newprossedDataArray.sort((a, b) => b?.[fieldName].localeCompare(a?.[fieldName]));
            }
        }

        console.log(sort);

        return newprossedDataArray;
    };

    // fetch metadata
    // fetchProcessedData = async (userType) => {
    //     admin

    //     let dataCount = await UserService.getMetaDataCount(userType, this.currentUserId);
    //     dataCount = dataCount.count;
    //     const OFFSET_RANGE = 10;
    //     const LIMIT_RANGE = 10;

    //     const offsetCount = 0;
    //     const limit = LIMIT_RANGE;

    //     let finalFetchedData;
    //     finalFetchedData = [];
    //     async function fetchDataInChunks(iterateCount: any, dataOffsetCount: any, dataLimit, that): Promise<any> {
    //         let count = iterateCount;
    //         let offset = dataOffsetCount;

    //         if (count > 0) {
    //
    //             // fetch data in chunks
    //             const fetchedMetaData = await UserService.getMetaData_v2(userType, that.currentUserId, dataOffsetCount, dataLimit);
    //             const newprossedDataArray = that.refactorMetadata(fetchedMetaData.metaData);
    //             // eslint-disable-next-line no-param-reassign
    //             finalFetchedData = [...finalFetchedData, ...newprossedDataArray];
    //             // updating main state metadata with merged fetched data
    //             runInAction(() => {
    //                 // eslint-disable-next-line no-param-reassign
    //                 that.cvMetaData = finalFetchedData;
    //             });

    //             setTimeout(() => {
    //                 // set recursive count states
    //                 count -= OFFSET_RANGE;
    //                 offset += OFFSET_RANGE;

    //                 if (iterateCount === finalFetchedData.length) {
    //                     count = 0;
    //                 }
    //                 fetchDataInChunks(count, offset, dataLimit, that);
    //             }, 1000);
    //             return finalFetchedData;
    //         }

    //         return finalFetchedData;
    //     }

    //     await fetchDataInChunks(dataCount, offsetCount, limit, this);
    // };

    clearcvMetadata() {
        LogService.info("clearing cvmetadata");
        this.cvMetaData = [];
    }

    get candidatesData() {
        LogService.info("candidatesData: triggered");
        return this.cvMetaData?.length > 0 && this.refactorMetadata(this.cvMetaData);
    }

    /**----------------------
     * fetch cvstatus data
     * ---------------------*/

    // fetch metadata
    updateMetaData = async (fetchedMetaData) => {
        runInAction(() => {
            // eslint-disable-next-line no-param-reassign
            this.cvMetaData = fetchedMetaData;
        });
    };

    updateTotalDataCount = async (userType) => {
        let dataCount;
        console.log(userType);

        // IF USERTYPE IS NOT SUPERADMIN THEN ONLY FETCH METADATA AND COUNT
        if (userType !== "superadmin") dataCount = await UserService.getMetaDataCount(userType, this.currentUserId);
        if (dataCount) dataCount = dataCount.count;
        if (!dataCount) dataCount = 0;
        if (dataCount > 0) this.totalDataCount = dataCount;

        // get processed data count
        let candidateCount;
        if (userType !== "superadmin") candidateCount = await UserService.getMetaDataCount(userType, this.currentUserId, `processed`);
        if (candidateCount) candidateCount = candidateCount.count;
        if (!candidateCount) candidateCount = 0;
        if (candidateCount > 0) this.processedDataCount = candidateCount;
    };

    // add cvStatus data to state
    // eslint-disable-next-line
    public setCvStatusData (data: any) {
        if (data.length > 0) {
            this.cvStatusData = data;
            return this.cvStatusData;
        }
    }

    // eslint-disable-next-line
    public updateCvData(id, updatedData: any, processedData = false) {
        const currentData = this.cvStatusData;
        if (currentData.length > 0) {
            const data = currentData.filter((item) => item._id === id);
            if (data.length > 0) {
                const matchingData = data[0];
                console.log(toJS(matchingData));
                // eslint-disable-next-line no-restricted-syntax
                for (const key in matchingData) {
                    if (key === "candidateName") {
                        // eslint-disable-next-line
                        matchingData[key] = updatedData["name"];
                    } else if (key === "candidateEmail") {
                        // eslint-disable-next-line
                        matchingData[key] = updatedData["email"];
                    } else if (key === "dataOrigin") {
                        // eslint-disable-next-line
                        matchingData[key] = updatedData["dateOrigin"];
                    } else if (processedData && key === "processedDataId") {
                        // eslint-disable-next-line
                        matchingData[key]["processedData"] = processedData;
                        // console.log(`processed -> ${toJS()}`);
                    }
                }
                // const updatedData = currentData.filter((item) => item._id !== id).concat([matchingData]);
                const oldData = currentData.filter((item) => item._id !== id);
                const newData = [matchingData].concat(oldData);
                this.setCvStatusData(newData);
            }
        }
    }
}
